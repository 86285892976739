import {useState} from 'react';
import {isAllowed} from "../services/access-control-client";
import {useAuth} from "./useAuth.ts";


let testRole = ''

const testMode = false
// testRole = 'User'
// testRole = 'TenantAdmin'
if (testMode) {
    testRole = 'TenantAdmin'
}

export const useAccessControl = () => {

    const {role} = useAuth();

    const [userRoles, setUserRoles] = useState<string[]>([role] as string[]);

    if (testRole) {
        setUserRoles([testRole])
    }

    const evaluateAction = (action: string) => {

        if (typeof action === 'undefined') {
            throw new Error('action is required parameter');
        }

        if (Array.isArray(userRoles) && userRoles.length === 0) {
            throw new Error('user roles is required parameter');
        }

        // remove undefined entries in the roles array - like this -  [undefined]
        const filteredRoles = userRoles.filter(role => role !== undefined);
        if (filteredRoles.length === 0) {
            filteredRoles.push('User');
        }

        try {
            return isAllowed(filteredRoles, action);
        } catch (e) {
            console.error('EXCEPTION IN EVALUATE ACTION: ', e);
            return false;
        }
    };

    return {
        userRoles,
        evaluateAction
    }

};

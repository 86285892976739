import {Avatar} from '@mantine/core';
import {BiAt} from "react-icons/bi";

export const RecordIcon = ({
                               size,
                           }: {
    size?: string
}) => {
    return (
        <Avatar size={size}>
            <BiAt/>
        </Avatar>
    );
};

RecordIcon.defaultProps = {
    size: 'md',
}




import {
    Box,
    Grid,
} from '@mantine/core';

import {ProfileWidget} from './dashboard/ProfileWidget.tsx';
import {RegistrarsWidget} from './dashboard/RegistrarsWidget.tsx';
import {useAccessControl} from "../../../hooks/useAccessControl.ts";
import {ReactNode} from "react";
import {DomainNamesWidget} from "./dashboard/DomainNamesWidget.tsx";
import {TopLevelDomainsWidget} from "./dashboard/TopLevelDomainsWidget.tsx";

export const PortalDashboard = () => {

    const {evaluateAction} = useAccessControl()

    const columns = {
        leftColumn: [],
        rightColumn: []
    } as { leftColumn: ReactNode[], rightColumn: ReactNode[] }

    if (evaluateAction('ViewDashboard')) {
        columns.leftColumn.push(<ProfileWidget/>)
    }
    if (evaluateAction('ListDomainNames')) {
        columns.leftColumn.push(<DomainNamesWidget/>)
    }


    if (evaluateAction('ListRegistrars')) {
        columns.rightColumn.push(<RegistrarsWidget/>)
    }

    if (evaluateAction('ListTopLevelDomains')) {
        columns.rightColumn.push(<TopLevelDomainsWidget/>)
    }


    return (
        <Grid mx={'10%'}>

            <Grid.Col span={{
                xl: 6,
                lg: 6,
                md: 12,
                sm: 12,
                xs: 12,
            }}>
                {columns.leftColumn.map((item, index) => <Box key={index}>{item}</Box>)}
            </Grid.Col>

            <Grid.Col span={{
                xl: 6,
                lg: 6,
                md: 12,
                sm: 12,
                xs: 12,
            }}>
                {columns.rightColumn.map((item, index) => <Box key={index}>{item}</Box>)}
            </Grid.Col>

        </Grid>
    );
};

import '@aws-amplify/ui-react/styles.css';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';


import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";
import {AppLayout} from "./layout/AppLayout.tsx";
import {PublicHomePage} from "./features/home/pages/PublicHomePage.tsx";
import {PublicDetailPage} from "./features/domain-names/pages/PublicDetailPage.tsx";
import {AboutPage} from "./features/home/pages/AboutPage.tsx";
import {ProductsPage} from "./features/home/pages/ProductsPage.tsx";
import {PricingPage} from "./features/home/pages/PricingPage.tsx";
import {SignOutPage} from "./features/home/pages/SignOutPage.tsx";
import {PageNotFound} from "@flexinet/ui-components";
import {IndexPage as DashboardPage} from "./features/dashboard/pages/IndexPage.tsx";
import {IndexPage as GettingStartedPage} from "./features/getting-started/pages/IndexPage.tsx";
import {DomainNamesLayout} from "./features/domain-names/layout.tsx";
import {RegistrarsLayout} from "./features/registrars/layout.tsx";
import {TopLevelDomainsLayout} from "./features/top-level-domains/layout.tsx";
import {SettingsLayout} from "./features/settings/layout.tsx";
import {ServicePlansLayout} from "./features/service-plans/layout.tsx";
import {MantineProvider} from "@mantine/core";
import {theme} from "./theme.ts";


import {UserAttributesContextProvider} from "./context/UserAttributesContext.tsx";
import {Notifications} from "@mantine/notifications";
import {RequireAuth} from "./utils/RequireAuth.tsx";


import 'aws-amplify/auth/enable-oauth-listener';
import {
    fetchUserAttributes,
    getCurrentUser
} from 'aws-amplify/auth';
import {Hub} from 'aws-amplify/utils';
import {AuthProvider} from "./context/AuthContext.tsx";


const router = createBrowserRouter([{
    path: '/',
    element: <AppLayout/>,
    children: [{
        index: true,
        element: <PublicHomePage/>
    }, {
        path: 'name/:name',
        element: <PublicDetailPage/>
    }, {
        path: 'about',
        element: <AboutPage/>
    }, {
        path: 'products',
        element: <ProductsPage/>
    }, {
        path: 'pricing',
        element: <PricingPage/>
    }, {
        path: 'sign-out',
        element: <SignOutPage/>
    }, {
        path: 'dashboard',
        element: <RequireAuth><DashboardPage/></RequireAuth>
    }, {
        path: 'getting-started',
        element: <RequireAuth><GettingStartedPage/></RequireAuth>
    }, {
        path: 'domain-names/*',
        element: <DomainNamesLayout/>
    }, {
        path: 'registrars/*',
        element: <RequireAuth><RegistrarsLayout/></RequireAuth>
    }, {
        path: 'top-level-domains/*',
        element: <RequireAuth><TopLevelDomainsLayout/></RequireAuth>
    }, {
        path: 'settings/*',
        element: <RequireAuth><SettingsLayout/></RequireAuth>
    }, {
        path: 'service-plans/*',
        element: <ServicePlansLayout/>
    }, {
        path: '*',
        element: <PageNotFound message={'Not Found'}/>
    }]
}])

Hub.listen("auth", async ({payload}) => {
    switch (payload.event) {
        case "signInWithRedirect":
            const user = await getCurrentUser();
            const userAttributes = await fetchUserAttributes();
            console.debug({
                              user,
                              userAttributes
                          });
            break;
        case "signInWithRedirect_failure":
            // handle sign in failure
            console.debug('SIGN IN WITH REDIRECT FAILURE: ', payload.data);
            break;
        case "customOAuthState":
            const state = payload.data; // this will be customState provided on signInWithRedirect function
            console.debug('CUSTOM STATE: ', state);
            break;
    }
});


export const App = () => {


    return (
        <MantineProvider theme={theme}>
            <AuthProvider>
                <UserAttributesContextProvider>
                    <Notifications position={'bottom-left'}/>
                    <RouterProvider router={router}/>
                </UserAttributesContextProvider>
            </AuthProvider>
        </MantineProvider>
    );

}


import {RecordForm} from '../forms/RecordForm';


import {useDomainName} from '../hooks/useDomainName';
import {IndexPageNavigation} from "../components/IndexPageNavigation";
import {FormPageLayout} from "@flexinet/ui-components";
import {useRegistrars} from "../../registrars";
import {Title} from "@mantine/core";
import {NavLink} from 'react-router-dom';
import {useTopLevelDomains} from "../../top-level-domains";
import {useEffect} from "react";


export const AddPage = () => {

    const {
        record,
        createRecord,
    } = useDomainName();

    const {
        items: registrars,
        fetchRecords: fetchRegistrars
    } = useRegistrars()
    const {
        items: tlds,
        fetchRecords: fetchTopLevelDomains,
    } = useTopLevelDomains()

    useEffect(() => {
        fetchRegistrars();
        fetchTopLevelDomains();
    }, [])

    return (
        <FormPageLayout
            title='Add Domain Name'
            pageNavigation={<IndexPageNavigation/>}
        >
            {registrars.length === 0 || tlds.length === 0 ?
                <Title order={3}>Please, <NavLink to={'/registrars/add'}>add at least one registrar</NavLink> before
                    creating domain name entry.</Title> :
                <RecordForm record={record} handleSubmit={createRecord}/>}

        </FormPageLayout>
    );
};

import {userPermissionStatements} from "./user.ts";

export const tenantAdminPermissionStatements = userPermissionStatements.concat([
                                                                                   {
                                                                                       Effect: 'Allow',
                                                                                       Action: [
                                                                                           'ViewDomainsApplication',
                                                                                       ],
                                                                                       Resource: '*',
                                                                                   },
                                                                                   {
                                                                                       Effect: 'Allow',
                                                                                       Action: [
                                                                                           'GetTenant',
                                                                                           'UpdateTenant',
                                                                                       ],
                                                                                       Resource: '*',
                                                                                   },
                                                                                   {
                                                                                       Effect: 'Allow',
                                                                                       Action: [
                                                                                           'ListPayments',
                                                                                           'GetPayment',
                                                                                           'CreatePayment',
                                                                                           'UpdatePayment',
                                                                                       ],
                                                                                       Resource: '*',
                                                                                   },
                                                                                   {
                                                                                       Effect: 'Allow',
                                                                                       Action: [
                                                                                           'ListTenantUsers',
                                                                                           'GetTenantUser',
                                                                                           'CreateTenantUser',
                                                                                           'UpdateTenantUser',
                                                                                       ],
                                                                                       Resource: '*',
                                                                                   },
                                                                               ])

export default {
    roleName: 'TenantAdmin',
    permissionStatements: tenantAdminPermissionStatements,
};

import {Avatar} from '@mantine/core';

import {AiOutlineUnorderedList} from 'react-icons/ai';

export const RecordIcon = ({
                               size,
                           }: {
    size?: string
}) => {
    return (
        <Avatar size={size}>
            <AiOutlineUnorderedList/>
        </Avatar>
    );
};

RecordIcon.defaultProps = {
    size: 'md',
}




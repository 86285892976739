import {
    useNavigate,
    useParams
} from 'react-router-dom';

import {
    Card,
    Center,
    Grid,
    Group,
    Image,
    Stack,
    Title
} from '@mantine/core';

import {FiAtSign} from 'react-icons/fi'
import {FlexiButton} from "@flexinet/ui-components";
import {AiOutlineHome} from "react-icons/ai";

export const PublicDetailPage = () => {

    let {name} = useParams();

    console.debug(name)

    const navigate = useNavigate()

    if (typeof name !== 'undefined' && name.indexOf('flexi') === -1) {
        name = 'Sorry...! Domain Name Not Found'
    }

    return (
        <Grid>
            <Grid.Col span={{
                xs: 0,
                sm: 0,
                md: 1,
                lg: 2
            }}/>
            <Grid.Col span={{
                xs: 12,
                sm: 12,
                md: 8,
                lg: 8
            }}>
                <Stack>
                    <Center>
                        <Group>
                            <FiAtSign size={24}/>
                            <Title order={1}>{name}</Title>
                        </Group>
                    </Center>
                    <Center mt="xl">
                        <Card shadow="xl" p="lg" radius="md">
                            <Grid>
                                <Grid.Col span={{
                                    xs: 12,
                                    sm: 12,
                                    md: 7,
                                    lg: 7
                                }}>
                                    <Center>
                                        <Image
                                            src={"https://s3.amazonaws.com/cdn.flexi.network/flexi.email/images/themes/flexi_email_header.jpg"}/>
                                    </Center>
                                </Grid.Col>
                                <Grid.Col span={{
                                    xs: 12,
                                    sm: 12,
                                    md: 5,
                                    lg: 5
                                }}>
                                    <Center>
                                        <Stack>
                                            <Title order={2} p={"xl"} style={{textAlign: "center"}}>
                                                Manage your domain names portfolio across registrars
                                            </Title>
                                        </Stack>
                                    </Center>

                                    <Center m="xl">
                                        <Group>
                                            <FlexiButton onClick={() => navigate('/')}
                                                         leftSection={<AiOutlineHome size={26}/>}>
                                                <Title order={4}>Learn More</Title></FlexiButton>
                                        </Group>
                                    </Center>

                                </Grid.Col>
                            </Grid>


                        </Card>
                    </Center>
                </Stack>
            </Grid.Col>
            <Grid.Col span={{
                xs: 0,
                sm: 0,
                md: 1,
                lg: 2
            }}/>
        </Grid>

    );
};

import {IRBACPermissionStatement} from "../../types";

export const evaluatePolicyStatement = (statement: IRBACPermissionStatement, action: string, resource: string) => {
    const actionEvalResult = evaluateAction(statement, action);
    const resourceEvalResult = evaluateResource(statement, resource);
    return Boolean(actionEvalResult) && Boolean(resourceEvalResult);
};

export const evaluateAction = (statement: IRBACPermissionStatement, action: string) => {
    const policyAction = statement.Action;

    if (Array.isArray(policyAction)) {
        return (
            Boolean(policyAction.indexOf('*') !== -1) ||
            Boolean(policyAction.indexOf(action) !== -1)
        );
    } else {
        return (
            Boolean(policyAction === '*') || Boolean(policyAction === action)
        );
    }
};

export const evaluateResource = (statement: IRBACPermissionStatement, resource: string) => {
    const policyResource = statement.Resource;

    if (typeof policyResource as any == 'object' && policyResource as any instanceof Array) {
        return (
            Boolean(policyResource.indexOf('*') !== -1) ||
            Boolean(policyResource.indexOf(resource) !== -1)
        );
    } else {
        return Boolean(policyResource === '*' || policyResource === resource);
    }
};

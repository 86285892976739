export default {
    roleName: 'Admin',
    permissionStatements: [
        {
            Effect: 'Allow',
            Action: ['*'],
            Resource: '*',
        },
    ],
};

import {
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';

import {IndexPage} from './pages/IndexPage';

import {useAccessControl} from '../../hooks/useAccessControl';

export const SettingsLayout = () => {

    const {evaluateAction} = useAccessControl()

    return (
        <Routes>
            <Route
                path='/'
                element={
                    evaluateAction('ListSettings') ? (
                        <IndexPage/>
                    ) : (
                        <Navigate to='/'/>
                    )
                }
            >

                <Route
                    path=':tab'
                    element={
                        evaluateAction('ListSettings') ? (
                            <IndexPage/>
                        ) : (
                            <Navigate to='/'/>
                        )
                    }
                />

            </Route>

        </Routes>
    );
};

import PropTypes from 'prop-types';

import {useNavigate} from 'react-router-dom';

import {Image} from '@mantine/core';
import config from "../../config/index";


export const MediumLogo = ({
                               w,
                               h
                           }: {
    w: string,
    h: string
}) => {
    const navigate = useNavigate();

    return (
        <Image
            src={'600x200_logo.png'}
            w={w}
            h={h}
            alt={config.appTitle}
            onClick={() => navigate('/')}
        />
    );
};

MediumLogo.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
};

MediumLogo.defaultProps = {
    w: '600px',
    h: '200px',
};

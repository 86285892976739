import {
    getDomainName,
    listDomainNames
} from './queries';
import {
    createDomainName,
    deleteDomainName,
    updateDomainName,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {DomainName} from "../types.ts";
import {generateClient} from "aws-amplify/api";
import {
    showErrorNotification,
    showErrorNotifications,
    showSuccessNotification
} from "../../../components/ServiceNotifications.tsx";
import {V6Client} from '@aws-amplify/api-graphql';


export type ListDomainNames = {
    listDomainNames: { items: DomainName[] }
}

export type GetDomainName = {
    getDomainName: DomainName
}

export type CreateDomainName = {
    createDomainName: DomainName
}

export type UpdateDomainName = {
    updateDomainName: DomainName
}

export type DeleteDomainName = {
    deleteDomainName: string
}

let client: V6Client
try {
    client = generateClient();
} catch (e: any) {
    showErrorNotification(e)
}

export const fetchRecords = async (filter: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<ListDomainNames>>({
                                                                                 query: listDomainNames,
                                                                                 variables: {filter: filter},
                                                                             });
        return response.data?.listDomainNames.items;
    } catch (e) {
        console.debug(e);
        showErrorNotifications(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetDomainName>>({
                                                                               query: getDomainName,
                                                                               variables: {id: id},
                                                                           });
        return response.data?.getDomainName;
    } catch (e) {
        showErrorNotifications(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateDomainName>>({
                                                                                  query: createDomainName,
                                                                                  variables: {input: payload},
                                                                              });
        console.debug('in the service response', response);

        showSuccessNotification();
        return response.data?.createDomainName;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const update = async (id: string, record: DomainName) => {

    console.debug('IN UPDATE DOMAIN NAME', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateDomainName>>({
                                                                                  query: (updateDomainName),
                                                                                  variables: {input: record},
                                                                              });

        showSuccessNotification();
        return response.data?.updateDomainName;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteDomainName>>({
                                                                                  query: (deleteDomainName),
                                                                                  variables: {input: {id: id}},

                                                                              });
        showSuccessNotification();
        return response.data?.deleteDomainName;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {TopLevelDomain} from '../types';

export const columns = [
    {
        title: I18n.get('Name'),
        width: '1fr',
        render: (item: TopLevelDomain) => <NavLink to={`/top-level-domains/${item.id}`}>{item.name}</NavLink>,
    },
    {
        title: I18n.get('Type'),
        width: '1fr',
        render: (item: TopLevelDomain) => item.tldType,
    },
    {
        title: I18n.get('Manager'),
        width: '1fr',
        render: (item: TopLevelDomain) => item.tldManager,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: TopLevelDomain) => formatTimestamp(item.created as string),
    },
];

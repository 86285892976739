export const userPermissionStatements = [
    {
        Effect: 'Allow',
        Action: [
            'ListDomainNames',
            'GetDomainName',
            'CreateDomainName',
            'UpdateDomainName',
            'DeleteDomainName',
        ],
        Resource: '*',
    },
    {
        Effect: 'Allow',
        Action: [
            'ListTopLevelDomains',
            'GetTopLevelDomain',
        ],
        Resource: '*',
    },
    {
        Effect: 'Allow',
        Action: [
            'ListRegistrars',
            'GetRegistrar',
            'CreateRegistrar',
            'UpdateRegistrar',
            'DeleteRegistrar',
        ],
        Resource: '*',
    },
    {
        Effect: 'Allow',
        Action: [
            'ViewDashboard',
            'ViewAccount',
            'ViewGettingStarted',
            'SignOut',
        ],
        Resource: '*',
    },
    // {
    //     Effect: 'Allow',
    //     Action: [
    //         'ListSettings',
    //     ],
    //     Resource: '*',
    // },
    {
        Effect: 'Allow',
        Action: [
            'ListServicePlans',
            'GetServicePlan',
        ],
        Resource: '*',
    },
    {
        Effect: 'Allow',
        Action: [
            'ListSubscriptions',
            'GetSubscription',
            'CreateSubscription',
            'UpdateSubscription',
        ],
        Resource: '*',
    },
]

export default {
    roleName: 'User',
    permissionStatements: userPermissionStatements,
};

import PropTypes from 'prop-types';
import {
    ActionIcon,
    Box,
    Card,
    Grid,
    Group,
    SimpleGrid,
    Skeleton,
    Stack,
    Text,
} from '@mantine/core';
import {formatTimestamp} from '../utils/TableTimestamps';
import {useAccessControl} from '../hooks/useAccessControl';
import {ReactElement} from 'react';
import {AiOutlineLink} from "react-icons/ai";

export const PropertiesCard = ({
                                   properties,
                                   cols,
                               }: {
    properties: {
        label: string,
        value: string | ReactElement | any,
        rbac?: string,
        span?: number,
        formatAs?: string
    }[],
    cols: number
}) => {

    const {evaluateAction} = useAccessControl();


    const allowedProprerties = properties.filter(property => {

        // if no rbac is specified allow it
        if (typeof property.rbac === 'undefined') {
            return true;
        } else {
            // if rbac property is specified - evaluate it and return accordingly
            if (evaluateAction(property.rbac)) {
                return true;
            }

            return false;

        }


    });

    return (
        <Card shadow='sm'>
            <Grid gutter={'sm'}>

                {allowedProprerties.map((item, index) => {

                                            let span = (12 / cols as number);
                                            if (typeof item.span !== 'undefined' && item.span !== null) {
                                                span = item.span;
                                            }

                                            let formattedValue: ReactElement | string = item.value;
                                            if (item.formatAs && item.formatAs === 'Timestamp') {
                                                formattedValue = formatTimestamp(item.value as string);
                                            }

                                            if (item.formatAs && item.formatAs === 'ActionIcon') {
                                                formattedValue = <Group>
                                                    <Box>{item.value}</Box>
                                                    <ActionIcon title='Copy' onClick={() => window.open(item.value)}>
                                                        <AiOutlineLink/>
                                                    </ActionIcon>
                                                </Group>
                                                ;
                                            }

                                            return (
                                                <Grid.Col key={index} span={span} style={{
                                                    borderStyle: "solid",
                                                    borderColor: 'var(--mantine-color-gray-1)'
                                                }}>
                                                    <Stack py={'sm'}>
                                                        <Text fw={100}>{item.label.toUpperCase()}</Text>
                                                        <Text fw={700}>{formattedValue}</Text>
                                                    </Stack>
                                                </Grid.Col>
                                            );
                                        },
                )}
            </Grid>
        </Card>
    );
};

PropertiesCard.propTypes = {
    properties: PropTypes.arrayOf(PropTypes.shape({
                                                      label: PropTypes.string,
                                                      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
                                                  })),
    cols: PropTypes.number,
};

PropertiesCard.defaultProps = {
    cols: 3,
};


export const PropertiesCardSkeleton = () => {
    return (
        <Card>
            <SimpleGrid cols={2} my='lg'>
                <Stack gap='lg' m='lg'>
                    <Skeleton height={15} mt={6} width='60%' radius='xl'/>
                    <Skeleton height={15} mt={6} width='100%' radius='xl'/>
                </Stack>
                <Stack gap='lg' m='lg'>
                    <Skeleton height={15} mt={6} width='60%' radius='xl'/>
                    <Skeleton height={15} mt={6} width='100%' radius='xl'/>
                </Stack>
                <Stack gap='lg' m='lg'>
                    <Skeleton height={15} mt={6} width='60%' radius='xl'/>
                    <Skeleton height={15} mt={6} width='100%' radius='xl'/>
                </Stack>
                <Stack gap='lg' m='lg'>
                    <Skeleton height={15} mt={6} width='60%' radius='xl'/>
                    <Skeleton height={15} mt={6} width='100%' radius='xl'/>
                </Stack>
            </SimpleGrid>
        </Card>
    );
};

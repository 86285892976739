import {
    SimplePageHeader,
    SimplePageLayout,
    TabbedComponent,
    TabComponentTab
} from '@flexinet/ui-components';
import {GeneralPage} from "./GeneralPage.tsx";
import {SubscriptionsPage} from "./SubscriptionsPage.tsx";
import {PaymentAndBillingPage} from "./PaymentAndBillingPage.tsx";
import {TenantMembersPage} from "./TenantMembersPage.tsx";
import {useParams} from "react-router-dom";
import {useAccessControl} from "../../../hooks/useAccessControl.ts";

export const IndexPage = () => {

    const {tab} = useParams()

    const {evaluateAction} = useAccessControl()

    const tabs = [] as TabComponentTab[]

    if (evaluateAction('ListGeneralSettings')) {
        tabs.push({
                      title: 'General',
                      component: <GeneralPage/>,
                      active: tab === 'general' ? true : false
                  },
        )
    }
    if (evaluateAction('ListSubscriptions')) {
        tabs.push({
                      title: 'Subscriptions',
                      component: <SubscriptionsPage/>,
                      active: tab === 'subscriptions' ? true : false
                  },
        )
    }
    if (evaluateAction('ListPayments')) {
        tabs.push({
                      title: 'Payment and Billing',
                      component: <PaymentAndBillingPage/>,
                      active: tab === 'payment-and-billing' ? true : false
                  }
        )
    }


    if (evaluateAction('ListTenantUsers')) {
        tabs.push({
                      title: 'Tenant Members',
                      component: <TenantMembersPage/>,
                      active: tab === 'tenant-members' ? true : false
                  }
        )
    }


    return (
        <SimplePageLayout
            pageHeader={<SimplePageHeader title={'Settings'}/>}
            pageNavigation={<div/>}
        >
            <TabbedComponent tabs={tabs}/>
        </SimplePageLayout>
    );
};
